<template>
	<div class="grayBack footerContainer setMenuColor" data-menu-color="dark">
		<div class="container3">
			<div class="footer fs14">
				<div>
					© {{year}} — все права защищены
				</div>
				<div>
					<a href="/privacy.pdf" class="hover-red">политика конфиденциальности</a>
				</div>
				<div>
					<span class="show1200Inline">адрес:</span> Казань, ул. Зур Урам, 1а
				</div>
				<div>
					<span class="show1200Inline">тел.:</span> <Phone class="hover-red"></Phone>
				</div>
				<div>
					<span class="show1200Inline">e-mail:</span> <a href="mailto:info@artrockets.ru" class="hover-red">info@artrockets.ru</a>
				</div>
			</div>
		</div>
	</div>
</template>


<style lang="sass">
	@import '@/assets/new_sass/Footer'
</style>

<script>
	export default {
		data: () => ({
			year: ""
		}),
		mounted(){
			this.year = new Date().getFullYear();
		},
		components: {
			Phone: () => import('@/components/new/Variables/Phone'),
		}
	}
</script>
